$fontSizes: (
  '3xs': $seres-font-size-3xs,
  '2xs': $seres-font-size-2xs,
  'xs': $seres-font-size-xs,
  'sm': $seres-font-size-sm,
  'md': $seres-font-size-md,
  'lg': $seres-font-size-lg,
  'xl': $seres-font-size-xl,
  '2xl': $seres-font-size-2xl,
  '3xl': $seres-font-size-3xl,
  'max': $seres-font-size-max,
);

$colors: (
  'primary-brand': $seres-color-primary-brand,
  'primary-dark': $seres-color-primary-dark,
  'primary-light': $seres-color-primary-light,
  'secondary-brand': $seres-color-secondary-brand,
  'secondary-dark': $seres-color-secondary-dark,
  'secondary-light': $seres-color-secondary-light,
  'neutral-black': $seres-color-neutral-black,
  'neutral-dark': $seres-color-neutral-dark,
  'neutral-darker-accent': $seres-color-neutral-darker-accent,
  'neutral-mid': $seres-color-neutral-mid,
  'neutral-light': $seres-color-neutral-light,
  'neutral-lighter-accent': $seres-color-neutral-lighter-accent,
  'neutral-white': $seres-color-neutral-white,
  'sup-yellow-darker-accent': $seres-color-sup-yellow-darker-accent,
  'sup-yellow-dark': $seres-color-sup-yellow-dark,
  'sup-yellow-mid': $seres-color-sup-yellow-mid,
  'sup-yellow-light': $seres-color-sup-yellow-light,
  'sup-green-darker-accent': $seres-color-sup-green-darker-accent,
  'sup-green-dark': $seres-color-sup-green-dark,
  'sup-green-mid': $seres-color-sup-green-mid,
  'sup-green-light': $seres-color-sup-green-light,
  'sup-red-darker-accent': $seres-color-sup-red-darker-accent,
  'sup-red-dark': $seres-color-sup-red-dark,
  'sup-red-mid': $seres-color-sup-red-mid,
  'sup-red-light': $seres-color-sup-red-light,
  'chart-purple-dark': $seres-color-chart-purple-dark,
  'chart-purple-mid': $seres-color-chart-purple-mid,
  'chart-purple-light': $seres-color-chart-purple-light,
  'chart-pink-dark': $seres-color-chart-pink-dark,
  'chart-pink-mid': $seres-color-chart-pink-mid,
  'chart-pink-light': $seres-color-chart-pink-light,
  'chart-blue-dark': $seres-color-chart-blue-dark,
  'chart-blue-mid': $seres-color-chart-blue-mid,
  'chart-blue-light': $seres-color-chart-blue-light,
  'chart-orange-dark': $seres-color-chart-orange-dark,
  'chart-orange-mid': $seres-color-chart-orange-mid,
  'chart-orange-light': $seres-color-chart-orange-light,
);

ptz-icon{
  display: flex;

  .srs-icon {
    display: inline-flex;
    align-self: center;

    @each $id, $color in $colors {
      &-#{$id} {
        color: $color;
      }
    }

    @each $id, $fontSize in $fontSizes {
      &-#{$id} {
        font-size: $fontSize;
      }
    }
  }
}
