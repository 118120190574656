@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

ptz-skeleton {
  span {
    display: none;
  }

  div {
    user-select: none;
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: $petz-color-neutral-light;
    background: linear-gradient(110deg, $petz-color-neutral-lighter-accent 8%, $petz-color-neutral-light 18%, $petz-color-neutral-lighter-accent 33%);
    background-size: 200% 100%;
    animation: 1.2s shine linear infinite;
  }

  .ptz-rounded-none {
    border-radius: 0;
  }

  .ptz-rounded-2xs {
    border-radius: $petz-rounded-2xs;
  }

  .ptz-rounded-xs {
    border-radius: $petz-rounded-xs;
  }

  .ptz-rounded-sm {
    border-radius: $petz-rounded-sm;
  }

  .ptz-rounded-md {
    border-radius: $petz-rounded-md;
  }

  .ptz-rounded-lg {
    border-radius: $petz-rounded-lg;
  }

  .ptz-rounded-xl {
    border-radius: $petz-rounded-xl;
  }

  .ptz-rounded-2xl {
    border-radius: $petz-rounded-2xl;
  }

  .ptz-rounded-max {
    border-radius: $petz-rounded-max;
  }
}
