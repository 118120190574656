import { Component, h, Host, Prop } from '@stencil/core';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZIconTypes } from './types/ptz-icon.types';

@Component({
  tag: 'ptz-icon',
  styleUrls: ['ptz-icon.scss', 'srs-icon.scss'],
  shadow: false
})

export class PTZIcon {
  /** Define o estilo de qual marca será usado */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz

  /** Estado de renderização */
  @Prop() skeleton: boolean = false

  /** Cor do ícone */
  @Prop() color?: PTZIconTypes.Color

  /** Nome do ícone */
  @Prop() name: PTZIconTypes.Name

  /** Tamanho do ícone */
  @Prop() size?: PTZIconTypes.Size

  /** Tipo do ícone */
  @Prop() variant: PTZIconTypes.Variant

  /** Test ID para teste automatizado */
  @Prop() testId?: string;

  render() {
    const skeleton = {
      '4xs': 10,
      '3xs': 12,
      '2xs': 14,
      'xs': 16,
      'sm': 18,
      'md': 20,
      'lg': 24,
      'xl': 28,
      '2xl': 32,
      '3xl': 48,
      'max': 64,
    };

    return (
      <Host>
        {this.skeleton ? (
          <ptz-skeleton width={skeleton[this.size]} height={skeleton[this.size]} rounded="max" />
        ) : (
          <i
            data-testid={this.testId}
            class={`
          ds-icon-${this.variant}
          ds-${this.name}
          ${this.brand}-icon
          ${this.size ? `${this.brand}-icon-${this.size}` : ''}
          ${this.color ? `${this.brand}-icon-${this.color}` : ''}
        `}
          ></i>
        )}
      </Host>
    );
  }
}
