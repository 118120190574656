import { Component, Host, Prop, h } from '@stencil/core';
import { PTZSkeletonTypes } from './types/ptz-skeleton.types';

@Component({
  tag: `ptz-skeleton`,
  styleUrl: 'ptz-skeleton.scss',
  shadow: false,
})
export class PTZSkeleton {
  /** Largura do skeleton em pixels */
  @Prop() width?: number;

  /** Altura do skeleton em pixels */
  @Prop() height?: number = 100;

  /** Arredondamento do skeleton */
  @Prop() rounded?: PTZSkeletonTypes.Rounded = 'md';

  render() {
    return (
      <Host>
        <div class={`ptz-rounded-${this.rounded}`} style={this.width ? { width: `${this.width}px`, height: `${this.height}px` } : { height: `${this.height}px` }}>
          <span>
            <slot />
          </span>
        </div>
      </Host>
    );
  }
}
