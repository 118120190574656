$ptzFontSizes: (
  '3xs': $petz-font-size-3xs,
  '2xs': $petz-font-size-2xs,
  'xs': $petz-font-size-xs,
  'sm': $petz-font-size-sm,
  'md': $petz-font-size-md,
  'lg': $petz-font-size-lg,
  'xl': $petz-font-size-xl,
  '2xl': $petz-font-size-2xl,
  '3xl': $petz-font-size-3xl,
  'max': $petz-font-size-max,
);
$ptzColors: (
  'primary-brand': $petz-color-primary-brand,
  'primary-dark': $petz-color-primary-dark,
  'primary-light': $petz-color-primary-light,
  'primary-signature': $petz-color-primary-signature,
  'primary-black-friday': $petz-color-primary-black-friday,
  'secondary-brand': $petz-color-secondary-brand,
  'secondary-dark': $petz-color-secondary-dark,
  'secondary-light': $petz-color-secondary-light,
  'secondary-black-friday': $petz-color-secondary-black-friday,
  'neutral-black': $petz-color-neutral-black,
  'neutral-dark': $petz-color-neutral-dark,
  'neutral-darker-accent': $petz-color-neutral-darker-accent,
  'neutral-mid': $petz-color-neutral-mid,
  'neutral-light': $petz-color-neutral-light,
  'neutral-lighter-accent': $petz-color-neutral-lighter-accent,
  'neutral-white': $petz-color-neutral-white,
  'sup-yellow-darker-accent': $petz-color-sup-yellow-darker-accent,
  'sup-yellow-dark': $petz-color-sup-yellow-dark,
  'sup-yellow-mid': $petz-color-sup-yellow-mid,
  'sup-yellow-light': $petz-color-sup-yellow-light,
  'sup-green-darker-accent': $petz-color-sup-green-darker-accent,
  'sup-green-dark': $petz-color-sup-green-dark,
  'sup-green-mid': $petz-color-sup-green-mid,
  'sup-green-light': $petz-color-sup-green-light,
  'sup-red-darker-accent': $petz-color-sup-red-darker-accent,
  'sup-red-dark': $petz-color-sup-red-dark,
  'sup-red-mid': $petz-color-sup-red-mid,
  'sup-red-light': $petz-color-sup-red-light,
  'chart-purple-dark': $petz-color-chart-purple-dark,
  'chart-purple-mid': $petz-color-chart-purple-mid,
  'chart-purple-light': $petz-color-chart-purple-light,
  'chart-pink-dark': $petz-color-chart-pink-dark,
  'chart-pink-mid': $petz-color-chart-pink-mid,
  'chart-pink-light': $petz-color-chart-pink-light,
  'chart-blue-dark': $petz-color-chart-blue-dark,
  'chart-blue-mid': $petz-color-chart-blue-mid,
  'chart-blue-light': $petz-color-chart-blue-light,
  'chart-orange-dark': $petz-color-chart-orange-dark,
  'chart-orange-mid': $petz-color-chart-orange-mid,
  'chart-orange-light': $petz-color-chart-orange-light,
  'category-dog': $petz-color-category-dog,
  'category-reptile': $petz-color-category-reptile,
  'category-cat': $petz-color-category-cat,
  'category-bird': $petz-color-category-bird,
  'category-fish': $petz-color-category-fish,
  'category-cactus': $petz-color-category-cactus,
  'category-rabbit': $petz-color-category-rabbit,
  'clubz-primary-brand': $petz-color-clubz-primary-brand,
  'clubz-primary-light': $petz-color-clubz-primary-light,
  'clubz-secondary-brand': $petz-color-clubz-secondary-brand,
  'clubz-secondary-dark': $petz-color-clubz-secondary-dark,
  'clubz-offwhite': $petz-color-clubz-offwhite,
);
$srsFontSizes: (
  '3xs': $seres-font-size-3xs,
  '2xs': $seres-font-size-2xs,
  'xs': $seres-font-size-xs,
  'sm': $seres-font-size-sm,
  'md': $seres-font-size-md,
  'lg': $seres-font-size-lg,
  'xl': $seres-font-size-xl,
  '2xl': $seres-font-size-2xl,
  '3xl': $seres-font-size-3xl,
  'max': $seres-font-size-max,
);
$srsColors: (
  'primary-brand': $seres-color-primary-brand,
  'primary-dark': $seres-color-primary-dark,
  'primary-light': $seres-color-primary-light,
  'secondary-brand': $seres-color-secondary-brand,
  'secondary-dark': $seres-color-secondary-dark,
  'secondary-light': $seres-color-secondary-light,
  'neutral-black': $seres-color-neutral-black,
  'neutral-dark': $seres-color-neutral-dark,
  'neutral-darker-accent': $seres-color-neutral-darker-accent,
  'neutral-mid': $seres-color-neutral-mid,
  'neutral-light': $seres-color-neutral-light,
  'neutral-lighter-accent': $seres-color-neutral-lighter-accent,
  'neutral-white': $seres-color-neutral-white,
  'sup-yellow-darker-accent': $seres-color-sup-yellow-darker-accent,
  'sup-yellow-dark': $seres-color-sup-yellow-dark,
  'sup-yellow-mid': $seres-color-sup-yellow-mid,
  'sup-yellow-light': $seres-color-sup-yellow-light,
  'sup-green-darker-accent': $seres-color-sup-green-darker-accent,
  'sup-green-dark': $seres-color-sup-green-dark,
  'sup-green-mid': $seres-color-sup-green-mid,
  'sup-green-light': $seres-color-sup-green-light,
  'sup-red-darker-accent': $seres-color-sup-red-darker-accent,
  'sup-red-dark': $seres-color-sup-red-dark,
  'sup-red-mid': $seres-color-sup-red-mid,
  'sup-red-light': $seres-color-sup-red-light,
  'chart-purple-dark': $seres-color-chart-purple-dark,
  'chart-purple-mid': $seres-color-chart-purple-mid,
  'chart-purple-light': $seres-color-chart-purple-light,
  'chart-pink-dark': $seres-color-chart-pink-dark,
  'chart-pink-mid': $seres-color-chart-pink-mid,
  'chart-pink-light': $seres-color-chart-pink-light,
  'chart-blue-dark': $seres-color-chart-blue-dark,
  'chart-blue-mid': $seres-color-chart-blue-mid,
  'chart-blue-light': $seres-color-chart-blue-light,
  'chart-orange-dark': $seres-color-chart-orange-dark,
  'chart-orange-mid': $seres-color-chart-orange-mid,
  'chart-orange-light': $seres-color-chart-orange-light,
);
ptz-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  .ptz-icon {
    display: inline-flex;
    align-self: center;
    @each $id, $color in $ptzColors {
      &-#{$id} {
        color: $color;
      }
    }
    @each $id, $fontSize in $ptzFontSizes {
      &-#{$id} {
        font-size: $fontSize;
      }
    }
  }
  .srs-icon {
    display: inline-flex;
    align-self: center;
    @each $id, $color in $srsColors {
      &-#{$id} {
        color: $color;
      }
    }
    @each $id, $fontSize in $srsFontSizes {
      &-#{$id} {
        font-size: $fontSize;
      }
    }
  }
}
